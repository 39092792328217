
import LazyHydrate from "vue-lazy-hydration";

export default {
  name: "DrupalBlocks",
  props: ["blocks", "region"],
  components: {
    LazyHydrate,
    AroundMe: () => import("@/components/aroundMe/AroundMe"),
    Banner: () => import("@/components/banner/Banner"),
    ALaUne: () => import("@/components/articles/ALaUne"),
    FrontPageTags: () => import("@/components/widget/FrontPageTags"),
    SectionConnexion: () => import("@/components/sections/SectionConnexion"),
    SectionDonation: () => import("@/components/sections/SectionDonation"),
    SectionFolder: () => import("@/components/sections/SectionFolder"),
    SectionNewsletter: () => import("@/components/sections/SectionNewsletter"),
    SectionPlaylist: () => import("@/components/sections/SectionPlaylist"),
    SectionSerie: () => import("@/components/sections/SectionSerie"),
    SectionThematic: () => import("@/components/sections/SectionThematic"),
    SectionThematicVertical: () => import("@/components/sections/SectionThematicVertical"),
  },
  mounted() {
    this.alignImg();
  },
  methods: {
    isVisible(block) {
      if (!block.visibility) {
        return true;
      } else {
        let pathMatch =
          this.$route.path.substring(0, this.$route.path.lastIndexOf("/") + 1) +
          "*";
        if (!block.visibility.negate) {
          return (
            block.visibility.pages &&
            (block.visibility.pages.includes(pathMatch) ||
              block.visibility.pages.includes(this.$route.path) ||
              block.visibility.pages.includes("*"))
          );
        } else {
          return (
            !block.visibility.pages.includes(pathMatch) ||
            !block.visibility.pages.includes(this.$route.path)
          );
        }
      }
    },
    alignImg() {
      this.$ckEditorService.alignImg();
    },
  },
};
