
import { getUrlWithFormat } from "@/utils/formatImgUrl";

export default {
  name: "CardVertical",
components: {
  ButtonPlay: () => import("@/components/buttons/ButtonPlay"),
  Flags: () => import("@/components/flags/Flags"),
  Link: () => import("@/components/elements/Link"),
  CardContent: () => import("@/components/cards/CardContent"),
},
props: ["card", "verticalMobile", "serie_section", "type", "toPreload"],
methods: {
  getUrlWithFormat,
},
  computed: {
    isObfuscated() {
      if(this.card.type) {
        switch (this.card.type) {
          case 'show':
            return false
          case 'article':
            return false
          case 'episode':
            return true
          default:
            return true
        }
      }
    },
  },
};
