
import { mapMutations, mapState } from "vuex";
import CardVertical from "@/components/cards/CardVertical";

export default {
  name: "AroundMe",
  components: {
    SectionListInfo: () =>
      import("@/components/sections/SectionListInfo"),
  },
  props: ["block"],
  methods: {
    ...mapMutations({
      showModal: "modal/showModal",
    }),
  },
  computed: {
    ...mapState({
      radioName: (state) => {
        return state.radio.radioName
      },
      isNational: (state) => {
        return (state.radio.radioTid == null || state.radio.radioTid == 9)
      },
      aroundMeAlias: (state) => {
        return state.aroundme.aroundmeAlias
      }
    }),
    CardVertical() {
      return CardVertical
    }
  }
};
