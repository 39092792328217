import { render, staticRenderFns } from "./CardVertical.vue?vue&type=template&id=f081e07e"
import script from "./CardVertical.vue?vue&type=script&lang=js"
export * from "./CardVertical.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Flags: require('/web/components/flags/Flags.vue').default})
