
  export default {
    name: "Banner",
    props: [
      'region',
      'block'
    ],
    components: {
      BannerBlock : () => import("@/components/banner/BannerBlock")
    },
    data() {
      return {
        Banners: [],
        hasRegion: false,
      }
    },
    methods: {
      isVertical() {
        return (this.region === "side_bar")
      },
    },
    async fetch() {
      if (this.region) {
        let radio = this.$store.state.radio.radioTid ?? 9
        try {
          this.Banners = await this.$store.$regionRepository.getByIdWithQueryParam({id: 'banner_blocks/'+this.region+'/'+radio})
          this.hasRegion = true
        } catch(e) {
          this.Banners = []
          this.hasRegion = false
        }
      } else if (this.block) {
        // Si la prop 'region' n'est pas définie, on utilise les données fournies dans la prop 'block'.
        this.Banners = this.block
        this.hasRegion = false
      }
    },
  }
