import { render, staticRenderFns } from "./Banner.vue?vue&type=template&id=3900f39c"
import script from "./Banner.vue?vue&type=script&lang=js"
export * from "./Banner.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BannerBlock: require('/web/components/banner/BannerBlock.vue').default})
