
import { getUrlWithFormat } from "@/utils/formatImgUrl";

  export default {
    name: "BannerBlock",
    props: [
      'block',
      'isVertical'
    ],
    components: {
      Link : () => import("@/components/elements/Link.vue")
    },
    methods: {
      random : function(list){
        return Math.floor(Math.random() * list.length)
      },
      getUrlWithFormat,
    },
  }
